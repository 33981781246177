import React, { Component } from 'react'
import logo from './assets/UnderConstruction.png';
import './App.css';

export class App extends Component {
  render() {
    return (
      <div>      
         <img src={logo} className="App-logo" alt="logo" />
      </div>
    )
  }
}

export default App